function xmur3(str: string) {
  let h = 1779033703 ^ str.length;
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = (h << 13) | (h >>> 19);
  }
  return function () {
    h = Math.imul(h ^ (h >>> 16), 2246822507);
    h = Math.imul(h ^ (h >>> 13), 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
}

function mulberry32(a: number) {
  return function () {
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export const avatarGradientStyles = (id: string) => {
  const seed = xmur3(id);
  const rand = mulberry32(seed());
  const from = {
    h: rand() * 360,
    s: 50 + rand() * 20,
    l: 50 + rand() * 20,
  };
  const to = {
    h: from.h - (70 + rand() * 40),
    s: from.s,
    l: from.l,
  };

  return {
    '--tw-gradient-from': `hsl(${from.h},${from.s}%,${from.l}%)`,
    '--tw-gradient-to': `hsl(${to.h},${to.s}%,${to.l}%)`,
    '--tw-gradient-stops': 'var(--tw-gradient-from),var(--tw-gradient-to,rgba(215,60,29,0))',
  };
};

export const addressGradientStyles = (id: string) => {
  const seed = xmur3(id);
  const rand = mulberry32(seed());
  const from = {
    h: rand() * 360,
    s: rand() * 10,
    l: rand() * 100,
  };

  const to = {
    h: from.h - (70 + rand() * 40),
    s: from.s,
    l: rand() * 100,
  };

  return {
    '--tw-gradient-from': `hsl(${from.h},${from.s}%,${from.l}%)`,
    '--tw-gradient-to': `hsl(${to.h},${to.s}%,${to.l}%)`,
    '--tw-gradient-stops': 'var(--tw-gradient-from),var(--tw-gradient-to,rgba(215,60,29,0))',
  };
};
